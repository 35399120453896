var jquery = require('jquery');
var lodash = require('lodash');
var jqueryUi = require('jquery-ui');
var magnificPopup = require('magnific-popup');
var pubsub = require('pubsub.js');
var lazyloader = require('lazyloader');

var AriesBase = require('./libs/aries-base');
var AriesComponent = require('./libs/aries-component');
var Popup = require('./libs/popup');
var PubSub = require('./libs/pub-sub');
var PubSubMessageKeys = require('./utils/pubSubMessageKeys');
var cookies = require('./libs/cookies');

exports.jquery = jquery;
exports.lodash = lodash;
exports.jqueryUi = jqueryUi;
exports.magnificPopup = magnificPopup;
exports.lazyloader = lazyloader;
exports.pubsub = pubsub;

exports.AriesBase = AriesBase;
exports.AriesComponent = AriesComponent;
exports.Popup = Popup;
exports.PubSub = PubSub;
exports.PubSubMessageKeys = PubSubMessageKeys;
exports.cookies = cookies;
