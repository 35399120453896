module.exports = {
    /**
     * Initialize post message to/from all the contained iframe
     * @param {string} event name of the event
     * @param {Array} data data to be published with the event
     * @returns {boolean} true when the function is successfully executed
     */
    publishFrame: function _publishFrame(event, data){
        var errorFlag = false;
        
        if(event === 'resizeFrame') {
            data = [this.getFrameHeight()];
        } else {
            try {
                // IE crash issue in case jquery object
                if (data && data.length) {
                    data.forEach(function(element, index) {
                        if (element.jquery) {
                            data[index] = {};
                        }
                    });
                } else if (data && data.jquery) {
                    data = {};
                }
                data = JSON.stringify(data);
            } catch (err) {
                errorFlag = true;
            }
        }
        if(errorFlag)
            return false;
            
        if(window.self !== window.top){
            window.parent.postMessage({event:event, data: data}, '*');
        } else {
            Array.prototype.slice.call(window.document.querySelectorAll('.pci-data-frame')).forEach(function(element){
                element.contentWindow.postMessage({event: event, data: data}, '*');
            });
        }
        return true;
    },

    /**
     * Get height of the contained iframe
     * @returns {Number} htmlClientHeight the height of the contained iframe
     */
    getFrameHeight: function _getFrameHeight() {
        var htmlClientHeight = 0;
        $('html').find('[data-component-name]').each(function(){
            htmlClientHeight += this.clientHeight;
        });
        return htmlClientHeight;
    }
}