/*
 * Dependencies
 */
var $ = require('jquery');
require('magnific-popup');
var _ = require('lodash');
var AriesComponent = require('./aries-component');
var PubSub = require('./pub-sub');
var LazyLoad = require('lazyloader');
var msgResources = window.msgResources || {};
var PubSubMessageKeys = require('utils/pubSubMessageKeys');
var startWindowScroll = 0;
/*
 * Main class
 */
var PopupComponent = AriesComponent.extend({
  popupOptions: {},
  //Default values for popup options
  fixedContentPos: true,
  showPrintButton: false,
  fullScreen: false,
  selector: '.mi-popover',
  printText: msgResources.printText || 'Print',
  closeText: msgResources.closeText + ' ' || 'Close ',
  hiddenSpanText: msgResources.hiddenSpanText || 'this window',
  loadingText: msgResources.loadingText || 'Loading...',
  mainClassStyles: 'm-modal t-modal-lang',

  /*
   * Html for close button
   */
  closeMarkup: function() {
    if (!this.alternateCloseMarkup) {
      var closeIcon = (this.reverseCloseIcon) ? '%title%<span class="is-hidden-text">' + this.hiddenSpanText + '</span><i class="icon mfp-close icon-close"></i>' : '<i class="icon mfp-close icon-close"></i>%title% <span class="is-hidden-text">' + this.hiddenSpanText + '</span>';
      if (!this.showPrintButton) {
        return '<div class="m-modal-utilities"><button class="m-modal-close mfp-close is-hover-fix">' + closeIcon + '</button></div>';
      } else {
        return '<div class="m-modal-utilities">' + '<button id="win-util-print" class="m-modal-print" style="display:block;"><i class="icon icon-print"></i> ' + this.printText + '</button>' + '<button class="m-modal-close mfp-close is-hover-fix">' + closeIcon + '</button></div>';
      }
    } else {
      return this.alternateCloseMarkup;
    }
  },

  subscribe: {
    'POPUP_CLOSE': 'closePopup'
  },

  preventInitialization: false,

  /*
   * Class constructor
   */
  initialize: function(props) {
    'use strict';
    //set popup specific properties
    this.props = props;

    if ((!this.props || !this.props.selector) && !open) {
      console.error('Selector is mandatory. Please add selector.');
      this.preventInitialization = true;
    } else {
      this.selector = this.props.selector || '';
    }

    if (props.url) {
      this.url = props.url;
    }
    this.addClassOnmfpWrap = this.props.addClassOnmfpWrap;
    this.addClassOnmfpContent = this.props.addClassOnmfpContent;
    this.parent = this.props.parent;

    // set print button to show print button
    this.showPrintButton = (!_.isUndefined(this.props.showPrintButton) && _.isBoolean(this.props.showPrintButton)) ? this.props.showPrintButton : false;

    // set full screen to make the popup appear on full screen
    this.fullScreen = (!_.isUndefined(this.props.fullScreen) && _.isBoolean(this.props.fullScreen)) ? this.props.fullScreen : false;

    // manually handle click events
    this.manualHandle = (!_.isUndefined(this.props.manualHandle) && _.isBoolean(this.props.manualHandle)) ? this.props.manualHandle : false;

    // set removeScriptTags to remove script from the selected block.
    this.removeScriptTags = (!_.isUndefined(this.props.removeScriptTags) && _.isBoolean(this.props.removeScriptTags)) ? this.props.removeScriptTags : false;

    this.skipContainerClass = (!_.isUndefined(this.props.skipContainerClass) && _.isBoolean(this.props.skipContainerClass)) ? this.props.skipContainerClass : false;

    // reverse close icon
    this.reverseCloseIcon = (!_.isUndefined(this.props.reverseCloseIcon) && _.isBoolean(this.props.reverseCloseIcon)) ? this.props.reverseCloseIcon : false;

    this.ajaxLoaded = (!_.isUndefined(this.props.ajaxLoaded) && _.isBoolean(this.props.ajaxLoaded)) ? this.props.ajaxLoaded : true;

    this.open = (!_.isUndefined(this.props.open) && _.isBoolean(this.props.open)) ? this.props.open : false;

    this.sourceBlock = this.props.sourceBlock ? this.props.sourceBlock : '#layout-body-container';

    this.mainClass = this.props.mainClass || this.mainClassStyles;

    this.hideCloseButton = this.props.hideCloseButton || false;

    this.addBorder = this.props.addBorder;
    this.borderClass = this.props.borderClass;
    this.contentLoaded = this.props.contentLoaded;
    this.beforeClose = typeof(this.props.beforeClose) === 'function' ? this.props.beforeClose : null;
    this.afterClose = typeof(this.props.afterClose) === 'function' ? this.props.afterClose : null;
    this.items = this.props.items;
    this.type = this.props.type;
    this.image = this.props.image;
    this.alternateCloseMarkup = this.props.alternateCloseMarkup;
    this.closeOnContentClick = this.props.closeOnContentClick;
				this.closeOnBgClick = this.props.closeOnBgClick;  // Added to implement onBackgroundclose functionality
    this.avoidCloseOnContentClick = this.props.avoidCloseOnContentClick || null;    
    this.enableEscapeKey = (this.props.enableEscapeKey !== undefined) ? this.props.enableEscapeKey : true;
	  this.cacheAjaxContent = _.isBoolean(this.props.cacheAjaxContent) ? this.props.cacheAjaxContent : false;
    this.preventGlobalReinit = this.props.preventGlobalReinit || false;

    // Subscribe to PubSub messages
    this.subscribePubSubMessages();
    // Subscribe to DOM events
    this.subscribeDOMEvents();
  },

  /*
   * Call register function after creating object of this class to register popup
   */
  register: function() {
    if (!this.preventInitialization) {
      //set options for popup
      this._registerOptions();
      //initialize popup
      this._registerPopup();
    }
  },

  /**
   * Open the popup on demand from the tile javascript
   */
  openPopup: function() {
    $.magnificPopup.open(this.popupOptions);
  },

  /*
   * override if needed to perform some task before popup is opened
   */
  beforeOpen: function() {
    this.container.attr('aria-live', 'assertive');
    $('#page-container, .page-container').attr('aria-hidden', 'true');
    if($('html').hasClass('ios')){
      startWindowScroll = document.body.scrollTop;
    }
  },

  /*
   * parse Ajax response of popup
   */
  parseAjax: function(response) {
    var mp = $.magnificPopup.instance,
      currentLink = mp && mp.currItem && mp.currItem.el ? $(mp.currItem.el[0]) : null;

    //Parsing response to asynchronously load css and js related to popup component
    var frag = $(response.data);
    var cssTags = [],
      jsTags = [];
    var fragTags = frag.filter('script.component-tag, script#miDataLayer');
    var _self = this;
    var layoutContainer = frag.find('#layout-body-container');

    frag.filter('link.component-tag[rel="stylesheet"]').each(function() {
      cssTags.push($(this).attr('href'));
    });

    LazyLoad.css(cssTags);

    // Fetch component static files and process rendered components
    fragTags.filter('script.component-tag').each(function() {
      var src = $(this).attr('src');

      if (src) {
        jsTags.push(src);
      } else {
        eval($(this).text());
      }
    });

    // Fetch MDL from overlay and extend current window MDL
    fragTags.filter('script#miDataLayer').each(function() {
      var scriptText = $(this).text();
      var objString = scriptText.substring(scriptText.indexOf('{'), scriptText.indexOf('}') + 1);
      var overlayMDL = JSON.parse(objString);

      _self.pubsub.publish('ADD_TO_DATALAYER', [overlayMDL, {
        event_name: 'popup',
        event_description: 'Overlay with contents from: ' + (_self.url || (currentLink && currentLink.attr('href')))
      }]);
    });

    LazyLoad.js(jsTags, function() {
      PubSub.publishGlobal(_self.pubSubMessageKeys.GLOBAL_RENDER);
    });

    frag.filter('script[type=\'text/mi-context\']').each(function(i, tag) {
      $('body').prepend(tag);
    });

    if (this.removeScriptTags) {
      frag.find('script').remove();
    }
    if (!this.skipContainerClass) {
      layoutContainer.addClass('l-container');
    }

    // Error case scenario
    if (layoutContainer.find('.l-tile-message').length) {
      layoutContainer.addClass('l-padding-top l-padding-right l-padding-left');
    }

    var tmpFrag = frag.find(this.sourceBlock);
    if (tmpFrag.length) {
      frag = tmpFrag;
    } else {
      frag = frag.filter(this.sourceBlock);
    }

    response.data = frag;
    this.$el = this.parentEl();
    this.$el.find('noscript').remove();

    if (this.addBorder) {
      this.$el.find('.mfp-content').addClass(this.borderClass);
    }

    if (this.addClassOnmfpWrap) {
      this.$el.parent('.mfp-wrap').addClass(this.props.mfpWrapClassName);
    }

    //In case of full screen popup removing the padding from the main container and margin from the mpf content and assigning max width to it.
    if (this.fullScreen) {
      this.$el.addClass("l-padding-none");
      this.$el.find(".mfp-content").addClass("l-margin-none l-max-width-max l-width-max");
      this.$el.find(".mfp-content").css("height", "100%");
    }

    this.$el.find(".mfp-content").click(function(e) {
      if (!($(e.target).parents().hasClass('js-datepick-container')) && !($(e.target).hasClass('mfp-close')) && !($(e.target).hasClass('m-modal-print')) && !(e.target.nodeName === 'A' || e.target.nodeName === 'BUTTON')) {
        e.stopPropagation();
      }
    });
  },

  /*
   * After ajax response is parsed
   */
  ajaxContentAdded: function() {
    var modalTopFragment = '<div class="is-hidden-text">' + msgResources.topOfModalText + '</div>';
    var modalBottomFragment = '<div class="is-hidden-text">' + msgResources.bottomOfModalText + '</div>';

    if (this.showPrintButton) {
      //Print Functionality
      var closeBtn = this.$el.find('.mfp-close.m-modal-close'),
        printBtn = this.$el.find('.m-modal-print').show(),
        closeIcon = this.$el.find('.icon-close');
      closeIcon.prependTo(closeBtn);

      printBtn.on('click', function(e) {
        e.preventDefault();
        window.print();
      });
      $('#page-container').addClass('is-modal-print');
    }

    if (this.$el && this.$el.find('.analytics-click').length) {
      this.pubsub.publish('ANALYTICS_TRIGGER_CLICK', [this.$el.find('.analytics-click')]);
    }

    if (typeof window.$ !== 'undefined' && !this.preventGlobalReinit) {
      window.$(document).trigger('GLOBAL_ELEMENTS_REINIT');
    }

    if (this.open) {
      $('.mfp-container.mfp-ajax-holder.mfp-s-ready').addClass('mfp-inline-holder');
      $('.mfp-content .clearfix.l-container.sub-section').removeClass('l-container');
    }
    if (typeof this.contentLoaded === 'function') {
      this.contentLoaded();
    }

    //Removing content of button for standards 2.0 for resolving nvda bug. Hiding the cross text 'x' for sms notification on guestinfo
    $('.modal-content .mfp-close,.js-sms-term .mfp-close,.l-body-container .mfp-close').removeAttr('title').html('<span class="is-hidden-text">Close</span>');    


    // Removing <noscript> from the page
    $('.mfp-container noscript').remove();

    // Adding bounding elements for modal window
    if ($('.modal-content').length || $('.l-body-container').length) {
      $('.mfp-content').prepend(modalTopFragment);
      $(modalBottomFragment).insertBefore('.mfp-close');
      if ($('.mfp-modal-print').length) {
        $('.mfp-content').addClass('t-border-top-print-brand').addClass('t-border-top-print-override');
      }
    }
  },

  /*
   * override if needed
   */
  contentLoaded: function() {},
  /**
   * This method executes on after popup open
   */
  onPopupOpen: function() {
    var inlinePopUpCheck = this.image || (this.items && this.items.type === 'inline');
    if (!this.$el && inlinePopUpCheck) {
      /**
       * This is specific for image type inline popup.
       * Markup is defined for popup wrapper while initialization the popup within the tile js itself,
       */
      this.$el = this.parentEl();
    }
    if ($('.modal-content').length && (!$('.modal-content .mfp-close').length || this.image)) {
      this.ajaxContentAdded();
    }
    if (this.addClassOnmfpContent) {
      this.$el.find('.mfp-content').addClass(this.props.mfpContentClassName);
    }
    //Hiding close button
    if (this.hideCloseButton)
      $('.m-modal-close.mfp-close').hide();
    //Removing content of button for standards 2.0 for resolving nvda bug
    $('.modal-content .mfp-close,.l-body-container .mfp-close').removeAttr('title').html('<span class="is-hidden-text">Close</span>');

    if(this.avoidCloseOnContentClick) {
      this.$el.find(".mfp-content").on('click',function(e) {
        if (($(e.target).hasClass('mfp-content'))) {
          e.stopPropagation();
        }
      });
    }
    if($('html').hasClass('ios')){
      $('body').css('position','fixed');
      $('#stickySummary').css('position','static');
    }
  },
  /*
   * close popup function
   */
  closePopup: function() {
    // First line enables all pointer events on body which were previously disabled if auto complete suggestions open on a popup
    $('body').removeClass('disable-click');
    $('.is-global-disable').removeClass('is-global-disable');
    $.magnificPopup.close();
    $('#page-container').removeClass('is-modal-print');
    $('#page-container, .page-container').removeAttr('aria-hidden');
    if($('html').hasClass('ios')){
      $('body').css('position','');
      $('#stickySummary').css('position','');
      document.body.scrollTop = startWindowScroll;
    }
  },

  /*
   * returns parent selector of every popup
   */
  parentEl: function() {
    return $('.mfp-container');
  },

  /*
   * registers option for a popup
   */
  _registerOptions: function() {
    var popupOptions = {
      'tClose': this.closeText,
      'items': this.items,
      'tLoading': this.loadingText,
      'loadingText': this.loadingText,
      'mainClass': this.mainClass,
      'showPrintButton': this.showPrintButton,
      'closeOnContentClick': this.closeOnContentClick,
						'closeOnBgClick' : this.closeOnBgClick,
      'enableEscapeKey': this.enableEscapeKey,
      'fixedContentPos': true,
      callbacks: {
        'beforeOpen': this.beforeOpen,
        'close': this.closePopup,
        'open': this.onPopupOpen.bind(this),
        'beforeClose': this.beforeClose,
        'afterClose': this.afterClose
      }
    };
    if (!this.type) {
      popupOptions.items = this.items;
    } else {
      delete popupOptions.items;
      popupOptions.type = this.type;
    }
    if (this.image) {
      popupOptions.image = this.image;
    }

    // Whether close button by magnific popup should be overwritten or not
    if (this.closeMarkup() !== true) {
      popupOptions['closeMarkup'] = this.closeMarkup();
    }
    //pass ajaxLoaded - false, if popup not loaded through ajax call
    if (!_.isUndefined(this.ajaxLoaded) && this.ajaxLoaded) {
      popupOptions.callbacks.parseAjax = this.parseAjax.bind(this);
      popupOptions.callbacks.ajaxContentAdded = this.ajaxContentAdded.bind(this);
      popupOptions.type = popupOptions.type ? popupOptions.type : 'ajax';
      popupOptions.ajax = {
        settings: {
          dataType: 'html',
          cache: this.cacheAjaxContent
        }
      };
      if (!(_.isUndefined(this.url))) {
        popupOptions['items'] = {
          src: this.url
        };
      };
    }

    this.popupOptions = popupOptions;
  },

  /**
   * Register Magnific Popup
   * Pass option open - true, if need to open popup once initialized
   */
  _registerPopup: function() {
    (this.open) ? $.magnificPopup.open(this.popupOptions): $(this.selector).magnificPopup(this.popupOptions);

    if (this.manualHandle) $(this.selector).off('click');
  },
});

module.exports = PopupComponent;