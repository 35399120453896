var PubSub = window.MI_PubSub || require('pubsub.js');
var PubSubMessageKeys = require('utils/pubSubMessageKeys');
window.MI_PubSub = window.MI_PubSub || PubSub;

window.componentInstances = window.componentInstances || {};

window.MI_PubSub.ariesPubSub = window.MI_PubSub.ariesPubSub || {
  /**
   * Subscription maintainers
   */
  registeredSubscribeToEvents: {},
  globalSubscribedEvents: {},
  pubSubMessageKeys: PubSubMessageKeys,
  debounce: {},
  debouncePeriod: 500,
  exclusionList: ['dataLayer'],

  /**
   * Event subscriber for Aries components
   *
   * @param  {string}   type     type of the component
   * @param  {string}   event    name of the event
   * @param  {Function} callback event callback invoked with the received data
   */

  subscribeToEvent: function(type, event, callback) {
    'use strict';
    return PubSub.subscribe('component/' + type + '/' + event, callback);
  },

  /**
   * Global Event subscriber for Aries components
   *
   * @param  {Object} config Event config params
   */
  subscribeGlobal: function(config) {
    'use strict';
    var subscriptionID;

    if (typeof config.id === 'undefined') {
      config.id = 'global';
    }

    if (!this.globalSubscribedEvents[config.type]) {
      this.globalSubscribedEvents[config.type] = {};
    }

    if (!this.globalSubscribedEvents[config.type][config.id]) {
      this.globalSubscribedEvents[config.type][config.id] = {};
    }

    subscriptionID = this.globalSubscribedEvents[config.type][config.id][config.event];
    if (subscriptionID) {
      PubSub.unsubscribe(subscriptionID);
    }

    this.globalSubscribedEvents[config.type][config.id][config.event] = PubSub.subscribe(config.event, config.callback);
  },

  /**
   * Global Event publisher for Aries components
   *
   * @param  {string}   event    name of the event
   * @param  {Array}    data     data to be published with the event
   */
  publishGlobal: function(event, data) {
    'use strict';
    PubSub.publish(event, data);
  },

  /**
   * Event publisher for Aries components
   *
   * @param  {string}   type     type of the component
   * @param  {string}   event    name of the event
   * @param  {Array}    data     data to be published with the event
   */
  publishEvent: function(type, event, data) {
    'use strict';
    PubSub.publish('component/' + type + '/' + event, data);
  },

  /**
   * Event unsubscribe for Aries components
   *
   * @param  {string}   type           type of the component
   * @param  {string}   componentId    Id of the component
   * @param  {string}   event          name of the event
   */
  unSubscribeEvent: function(type, componentId, event) {
    var subscriptionID = this.globalSubscribedEvents[type][componentId][event];
    PubSub.unsubscribe(subscriptionID);
  },

  createObject: function(ComponentConstructor) {
    var obj = new ComponentConstructor({
      pubSub: this
    }),
    _self = this;

    if (_self.registeredSubscribeToEvents[obj.type]) {
      for (var k in _self.registeredSubscribeToEvents[obj.type]) {
        for (var j in _self.registeredSubscribeToEvents[obj.type][k]) {
          _self.unSubscribeEvent(_self.registeredSubscribeToEvents[obj.type][k][j]);
        }
      }
    }

    if (obj.subscribeEvents && obj.subscribeEvents.length) {
      for (var i in obj.subscribeEvents) {
        (function(o) {
          var subscribeEvent = _self.subscribeToEvent(obj.type, o.name, function() {
            if (o.callback) {
              o.callback.apply(obj, arguments);
            }
          });

          if (!_self.registeredSubscribeToEvents[obj.type]) {
            _self.registeredSubscribeToEvents[obj.type] = {};
          }

          if (!_self.registeredSubscribeToEvents[obj.type][o.name]) {
            _self.registeredSubscribeToEvents[obj.type][o.name] = [];
          }

          _self.registeredSubscribeToEvents[obj.type][o.name].push(subscribeEvent);
        })(obj.subscribeEvents[i]);

      }
    }

    return obj;
  },

  register: function(ComponentConstructor) {
    var type = ComponentConstructor.prototype.type;
    var _self = this;

    if (window.componentInstances[type]) {
      return;
    }

    /**
     * Subscribe to render event for binding events to rendered component
     */
    this.subscribeGlobal({
      type: type,
      event: 'component/' + type + '/' + _self.pubSubMessageKeys.RENDER,
      callback: function() {
        var obj = _self.createObject(ComponentConstructor);
        var mainArguments = Array.prototype.slice.call(arguments);
        mainArguments.push('render');
        obj.update.apply(obj, mainArguments);
      }
    });

    /**
     * Subscribe to render event for binding events to rendered component
     */
    this.subscribeGlobal({
      type: type,
      event: 'component/' + type + '/' + _self.pubSubMessageKeys.REFRESH,
      callback: function(compID) {
        var obj = window.componentInstances[type][compID];
        obj.refresh.apply(obj, arguments);
      }
    });

    /**
     * Subscribe to init event for binding events to rendered component
     */
    this.subscribeGlobal({
      type: type,
      event: 'component/' + type + '/' + _self.pubSubMessageKeys.INIT,
      callback: function(id, template) {
        try {
          if (typeof window.componentInstances[ComponentConstructor.prototype.type] === 'undefined') {
            window.componentInstances[ComponentConstructor.prototype.type] = {};
          }
          if (!window.componentInstances[ComponentConstructor.prototype.type][id]) {
            var obj = _self.createObject(ComponentConstructor);
            window.componentInstances[obj.type][id] = obj;
            obj.setElementAndBindEvents(id, template);
          } else {
            window.componentInstances[ComponentConstructor.prototype.type][id].setElementAndBindEvents(id, template);
          }
        } catch (err) {
          if (console && typeof console.error === 'function') {
            console.error(err);
          }
        }
      }
    });

    /**
     * Subscribe to reinitialize a component
     */
    if(this.exclusionList.indexOf(type) === -1){
      this.subscribeGlobal({
        type: type,
        event: 'component/' + type + '/' + _self.pubSubMessageKeys.RE_INIT,
        callback: function(id, template) {
          try {
            if (_self.debounce[id]) return;

            // If there are multiple re-init calls, reduce load on the
            // component initializer
            _self.debounce[id] = true;

            if (typeof window.componentInstances[ComponentConstructor.prototype.type] === 'undefined') {
              window.componentInstances[ComponentConstructor.prototype.type] = {};
            }

            var obj = _self.createObject(ComponentConstructor);
            window.componentInstances[obj.type][id] = obj;
            setTimeout(function() { _self.debounce[id] = false; }, _self.debouncePeriod);

            obj.setElementAndBindEvents(id, template);
          } catch (err) {
            if (console && typeof console.error === 'function') {
              console.error(err);
            }
          }
        }
      });
    }

    if (typeof makenComponents !== 'undefined') {
      if (typeof makenComponents.loadedScripts === 'undefined') {
        makenComponents.loadedScripts = [];
      }
      makenComponents.loadedScripts.push(type);
    }

    this.publishEvent(type, this.pubSubMessageKeys.REGISTERED, []);
  }
};

module.exports = window.MI_PubSub.ariesPubSub;
