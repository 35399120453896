
module.exports = {
  // Context specific events
  'JOINNOW_REWARDS_REFRESH': 'refresh/joinNowRewards',
  'ADDRESS_INFO_REFRESH': 'refresh/addressInfo',
  'CREDITCARD_REFRESH': 'refresh/creditCard',
  'USER_INFO_MOD': 'modified/userInformation',
  'USER_INPUT_CHANGE': 'modifiedInput/userInformation',
  'ERRORMSG_REFRESH': 'refresh/errorMessage',
  'TARGETTED_ERRORMSG_REFRESH': 'refresh/target/errorMessage',
  'JOINNOW_FORM_REFRESH': 'refresh/joinNowForm',
  'TRAVEL_AGENT_PLANNER_REFRESH': 'refresh/travelAgentAndPlanner',
  'EDIT_CLOSE_CLICK': 'click/editCloseButton',
  'GUEST_INFO_TOGGLE_SECTION_ONERROR': 'toggleOnError/guestInfo',
  'GUEST_INFO_TOGGLE_SECTION': 'toggle/guestInfo',
  'CLOSE_PAGENAV': 'click/pageNavigation',
  'EDIT_PREF_CLOSE': 'close/editpreferences',
  'CASH_POINTS_DIRTY': 'showRecalculate/cashAndPoints',
  'CASH_POINTS_DOM_MOD': 'domModified/cashAndPoints',
  'CASH_POINTS_RECALCULATE': 'recalculate/cashAndPoints',
  'CASH_AND_POINTS_INITIAL_LOAD': 'cashAndPointsInitialLoad/rateListMenu/buttonClick',
  'CASH_POINTS_SET_REWARDS_RULES': 'setRewardsRules/rateListMenu',
  'CASH_POINTS_CLEAR_TITLES': 'clearOrRemoveTitles/rateListMenuCashAndPoints',
  'CASH_POINTS_TOGGLE_TITLES': 'toggleCashAndPointsTitles/rateListMenu',
  'CASH_POINTS_CASH_ONLY_MESSAGE': 'toggleModalWindow/cashAndPoints/cashOnlyMessage',
  'MODAL_WINDOW_CASH_AND_POINTS': 'modalWindowToggle/rateListMenuPage/cashAndPointsComponent',
  'RLM_SUBMIT': 'submit/resListMenu',
  'ROOM_LM_REFRESH': 'refresh/roomListMenu',
  'ADVANCED_SEARCH_TAB_CLICK': 'toggle/advancedSearchOptions',
  'CREDITCARD_TOGGLE': 'toggle/creditCard/visaCheckoutScenario',
  'PAYMENT_OPTION_TOGGLE': 'toggle/creditCard/paymentOptionToggleScenario',
  'CREDITCARD_PP_OVERRIDE': 'creditCard/ppOverride',
  'CREDITCARD_PP_REMOVE_OVERRIDE': 'travelAgentAndPlanner/removeOverride',
  'ROOM_TYPE_SELECTION': 'update/roomDetailsFeatures',
  'TOGGLE_STICKY_SEARCH_FORM': 'toggle/stickyEditSearchForm',
  'OPTION_WIDGET_REFRESH': 'refresh/optionWidget',
  'TOGGLE_ERROR_MESSAGE': 'toggle/errorMessage',
  'TOGGLE_PAGE_HEADER': 'toggle/pageHeader',
  'REFRESH_MESSAGE_BOX': 'refresh/messageBox',
  'TOGGLE_STICKY_FORM_EDIT_BUTTON': 'toggle/editButtonStickySummary',
  'REFRESH_ACTIVITY_LIST': 'refresh/activityList',
  'FACEBOOK_LOGIN_POPULATE_ENROLLMENT_FORM': 'facebookLogin/populateEnrolmentForm',
  'LOGIN_INFO_MESSAGE_BOX': 'joinNowSocialLogin/showInfoBox',
  'NAVIGATION_TAB_CHANGE': 'nav/tabchange',
  'SHOW_FILTER_VIEW': 'show/searchFilters',
  'LAUNCH_MODAL_SIGN_IN': 'launchModal/signIn',
  'RELOAD_PAGE_ON_SOCIAL_EVENT': 'reloadPage/socialEvent',
  'RELOAD_PAGE_ON_LINKING': 'reloadPage/linkuser',
  'RELOAD_TILE_ON_DELINKING': 'reloadTile/delinkuser',
  'GUEST_ROOM_NAVIGATION': 'guestRoom/navigate',
  'UPDATE_RETURNTO_URL': 'loginSignup/updateReturnToUrl',
  'TOGGLE_STICKY_PANEL': 'listMap/searchSummary',
  'TOGGLE_HOTEL_DIRECTORY': 'toggle/hotleDirectory',
  'TOGGLE_HOTEL_DIRECTORY_CROSS_LINKS': 'toggle/hotleDirectory/crosslinks',
  'TOGGLE_CONNECT_US': 'toggle/connectus',
  'TOGGLE_HOTEL_DIRECTORY_RESULT_SUMMARY': 'toggle/hotelDirectory/ResultSummary',
  'TOGGLE_HOTEL_DIRECTORY_REGION_NAV': 'toggle/hotelDirectory/regionNav',
  'TOGGLE_PAGE_HEADER': 'toggle/page/header',
  'TOGGLE_GLOBAL_FOOTER': 'toggleGlobalFooter/globalFooter',
  'SCROLL_RESULT_SUMMARY': 'scrollResultSummary/hotelDirectoryResultSummary',
  'HIDE_STICKY_SEARCH_FORM': 'hideStickySearchForm/searchFormHorizontal',
  'HIDE_FILTERS_PANEL': 'hideFilterPanel/searchFilters',
  'SHOW_BRAND_FILTER' : 'showFilterPanel/searchFilters',
  'GOOGLE_MAP_MARKER_EVENTS': 'googleMapMarker/propertyRecords',
  'TOGGLE_MESSAGE_E_CERTIFICATE' : 'e_certificateMessageDisplay/BookNowAndCreditCard',
  'TOGGLE_PURCHASE_POINTS_BUTTON': 'purchasePointsButtonToggling/bookNow',
  'TOGGLE_PURCHASE_POINTS_CHECKBOX_STATUS': 'purchasePointsCheckboxStatusToggling/summaryOfCharges',
  'REFRESH_PROFILE_SAVED_CARDS': 'refresh/profileSavedCards',
  'RESET_AMENITIES_TABS': 'resetTabs/propertyRecords',
  'LIGHTBOX_LAUNCHER_REFRESH': 'refresh/lightboxLauncher',
  'DATALAYER_REFRESH': 'datalayer-refresh',
  'DATALAYER_REFRESH_AKAMAI':'datalayer-refresh-akamai',
  'SHOW_ASSOCIATE_NOTE': 'show/associateNote',
  'CLEAR_ASSOCIATE_NOTE': 'clear/associateNote',
  'HOTELFACTSHEET_REFRESH': 'refresh/pureHotelFactSheet',
  'CALLERTYPE_NAVBAR_REFRESH': 'refresh/callerTypeNavBar',
  'TRAVEL_PLANNER_NAVBAR_REFRESH': 'refresh/travelPlannerNavBar',
  'TRAVEL_PLANNER_FORM_REFRESH' : 'travelPlannerFormRefresh/travelPlannerForm',
  'TRAVEL_PLANNER_FORM_SELF_REFRESH' : 'refresh/travelPlannerForm',
  'CALLER_TYPE_FORM_REFRESH' : 'refresh/callerType',
  'RENDER_ACTIVITY_HEADER': 'render/activityHeader',
  'RENDER_HUB_ACTIVITY_LIST': 'render/hubActivityList',
  'SHOW_SIGNIN_OVERLAY': 'show/signInOverlay',
  'STICKY_PANEL_RESIZE': 'searchSummary/stickyPanelResize',
  'OPEN_HEADER_SEARCH_FORM': 'openHeaderSearchForm/searchFormHorizontal',
  'CLOSE_HEADER_SEARCH_FORM': 'closeOverlay/findAndReserve',
  'ESTIMATED_BASE_POINTS_EARNED':'show/estimatedBasePointsCalculator' ,
  'CURRENCY_CONVERTER_SECTION':'show/currencyCalculator',
  'REDEMPTION_POINTS_SECTION':'show/redemptionPointsCalculator' ,
  'HIDE-ALL-CALCULATOR-TABS' : 'hide/hideCalculatorTabContainer',
  'REFRESH_NEW_ENROLLMENT_HIGHLIGHTS' : 'render/newEnrollmentHighlights',
  'REFRESH_CREDIT_CARD_OFFER': 'refresh/creditCardOffer',
  'REFRESH_MOBILE_NUMBER_MISSING':'refresh/mobilePhoneValidation',
  'OPEN_LANGUAGE_SELECTOR_POPUP':'show/guestInfoLanguageSelector',
  'TOGGLE_CREDITCARD_VISA_CONTAINER': 'show/creditCard',
  'SELL_STRATEGY_SCROLL':'init/sellStrategyScroll',
  'ASSOCIATE_COMMENT_ERROR': 'show/associateCommentError',
  'SHOW_CONTACT_PHONE_EMAIL':'show/contactPhoneEmailResDetails',
  'HIDE_CONTACT_PHONE_EMAIL':'hide/contactPhoneEmailResDetails',
  'USER_INFO_FORM_REVIEW_RES_REFRESH':'refresh/userInformationFormResDetails',
  'ADDRESS_REWARDS_INFO_REFRESH': 'refresh/rewards/AddressInfo',
  'START_LOADER_ICON': 'start/loader/overlay',
  'STOP_LOADER_ICON': 'stop/loader/overlay',
  'INCREMENT_LOADER_COUNT': 'increment/loverder/overlay/count',
  'CONTACT_PHONE_EMAIL_REFRESH': 'refresh/contactEmailPhoneno',
  'CONTACT_PHONE_GUEST_INFO_TOGGLE_SECTION_ONERROR':'toggle/contactPhoneEmail',
  'CHECK_PHONE_EMAIL_ERROR':'toggle/userInformationReviewResDetails',
  'TOGGLE_GROUP_LOOKUP': 'lookupGroup/toggleDetails',
  'OPEN_GROUP_LOOKUP': 'listOfGroup/toggleGroupSearch',
  'GROUP_TAB': 'lookupGroup/toggleLookupGroup',
  'GROUP_TAB': 'listOfGroup/toggleLookupGroup',
  'SHOW_ALL_GROUP': 'listOfGroup/showAllGroup',
  'CHECK_FOR_TRAVEL_PLANNER_MOG': 'mog/checkForTravelPlanner',
  'TRAVEL_PLANNER_MOG': 'mog/matchTravelPlanner',
  'REFRESH_RED_CALC': 'refresh/redemptionPointsCalculator',
  'ESTIMATED_POINTS_SECTION': 'hideTile/estimatedBasePointsCalculator',
  'GUESTPIN_VALIDATED_CAL_TABS': 'refresh/navigationBar',
  'GUESTPIN_EVENT_CAL_TABS': 'refresh/navigationBar',
  'SELECT_DIRECTORY_REGION': 'setRegion',
  'SELECT_DIRECTORY_REGION_DETAIL': 'showRegion',
  'REGIONAL_NAV_TRIGGER': 'regionalNavTriggerClick',
  'UPDATE_CLICK_TO_SEARCH_DATA': 'updateClickToSearchData',
  'REFRESH_CONFIRMATION_MESSAGE_UPDATE': 'componentUpdate/confirmationMessage',
  'SHOW_DIRECTION_SEARCH_ERROR': 'drivingDirections/showAddressError',
  'SHOW_HWS_NAVIGATION_BAR': 'show/hwsNavigationBar',
  'HIDE_HWS_NAVIGATION_BAR': 'hide/hwsNavigationBar',
  'STICKY_BEHAVIOR_ADDED' : 'added/stickyBehavior',
  'STICKY_BEHAVIOR_REMOVED': 'removed/stickyBehavior',
  'CLEAR_MARZIPANO_DATA': 'mediaViewer/clearMarzipanoData',
  'USER_INFO_FORM_REVIEW_DETAILS_REFRESH' : 'refresh/userInformationForm/reviewResDetails',
  'TOGGLE_MESSAGE_REDEMPTION_OPTIONS': 'options/redemptionOptions',
  'CANCEL_RESERVATION_CHECKBOX_VAL': 'modifyTotalValue/resModifyActions',
  'TOGGLE_ADD_TO_CART': 'recieveProductId/cashAndPoints',
  'TOGGLE_SHOPPING_LINKS': 'toggleAddToCart/rateListMenu',
  'UPDATE_REDEMPTION_FLAG': 'updateRedemptionProduct/bookingCartRecords',
  'PREVIEW_COMBINATION_CLICKED': 'isPreviewCombinationClicked/rateListMenu',
  'RADIO_BUTTONS_STATUS': 'radioButtonStatus/rateListMenu',
  'TOGGLE_CALENDAR_VISIBILITY': 'searchFormHorizontal-forceSellFormERS/hideCalendar',
  'FORCE_SELL_RES_DATE' : 'ersForceSellableProducts/storeReservationDates',
  'RLM_TAB_CLICKED': 'rlmTabClicked/rateListMenu',
  'STICKY_CART_REFRESH': 'refresh/miniCart',
  'REFRESH_CART_PROGRESSBAR': 'refresh/cartProgressBar',
  'CART_RECORD_DETAIL' : 'searchResultLink/cartRecordInformation',
  'REMOVE_RLM_CART_ROOM': 'removeCartRoom/ersRateListMenu',
  'RLM_TOGGLE_PASS_KEY_ERROR': 'togglePassKeyError/ersRateListMenu',
  'TOGGLE_FOOTER_FILTER':'togglefooter/filter',

  'TOGGLE_EMAIL_ERROR_MESSAGE': 'userInformationForm/toggleEmailErrorMessage',
  'TOGGLE_PHONE_RESERVATIONS' : 'phoneReservations/togglePhoneReservations',
  'TOGGLE_SUMMARY_OF_CHARGES' : 'summaryOfCharges/toggleSummaryOfCharges',
  'TOGGLE_MEMBER_OPTIONS' : 'memberOptions/toggleMemberOptions',
  'TOGGLE_PAYS_BOOK_DIRECT' : 'paysBookDirect/togglePaysBookDirect',
  'TOGGLE_RECOMMENDED_UPGRADE' : 'recommendedUpgrade/toggleRecommendedUpgrade',
  'TOGGLE_BORDER' : 'toggle/toggleBorder',
  'CREDIT_CARD_POPUP_OPEN' : 'open/creditCardWidget',
  'UPDATE_CARD_NUMBER' : 'update/creditCardNumber',
  'CLOSE_CREDIT_CARD_WIDGET' : 'close/creditCardWidget',
  'CC_AUTH_ON_CLOSE': 'creditCardAuthForm/closeOverlayCallback',
  'GROUP_RFP_REQUEST_ADD': 'addRfpToList/groupSearchPropertyRecords',
  'GROUP_RFP_REQUEST_REMOVE': 'removeRfpFromList/groupSearchPropertyRecords',
  'GROUP_RFP_REMOVE_HOTEL': 'remove/RfpFromPropertyRecords',
  'GROUP_RFP_ADD_HOTEL': 'add/RfpToPropertyRecords',
  'SHOW_STICKY_GROUPS_SUMMARY': 'show/groupSearchSummary',
  'SHOW_GROUPS_SEARCH_FORM': 'show/groupSearchForm',

  'FILE_FORMAT_ERROR':'render/miniHotelCodeInputField',
  'FILTER_ROOMING_LIST':'domModified/eventSummaryList',
  'SEARCH_ROOMING_LIST':'refresh/eventSummaryList',
  'HIDE_ROOMING_SEARCH':'hide/eventSummaryList',
  'UPDATE_ADDRESS_FIELD':'update/uploadToMarsha',
  'SALES_FORCE_RECORDING_HANDLER':'salesForce/recordingHandler',
  'TOGGLE_RESTRICTED_INDICATOR': 'toggle/restrictedIndicator',

  // Generic functionality events
  'WIDGET_REFRESH': 'refresh/widget',
  'REFRESH_CREDIT_CARD_OFFER': 'refresh/creditCardOffer',
  'WIDGET_CLOSE': 'close/widget',
  'SPINNER_STOP': 'stop/spinner',
  'POPUP_CLOSE': 'close/popup',
  'ADD_TO_DATALAYER': 'add/dataLayer',
  'GOOGLE_MAP_API_LOADED': 'load/googleApi',
  'GUEST_ROOM_NAVIGATION': 'guestRoom/navigate',
  'GOOGLE_API_LOADED': 'googleMapLoader/googleApiLoader',
  'CONTACT_PHONE_EMAIL_MANDATORY_PLACEHOLDER' : 'email/mandatory/placeholder',
  'FETCH_PHONE_EMAIL_FORUSER_INFO_REVIEW_DETAILS':'fetch/email/phonenumber/for/userinformation/reviewdetailspage',
  'WINDOW_LOAD_EVENT': 'init/window',
  'ADJUST_MENU_HEIGHT': 'adjust/menuHeight',
  'BOOK_NOW_ENABLE_BUTTON': 'enable/booknow/continue',
  // grouptravel-roominglist related events
  'FILE_FORMAT_ERROR':'render/miniHotelCodeInputField',
  'FILTER_ROOMING_LIST':'domModified/eventSummaryList',
  'SEARCH_ROOMING_LIST':'refresh/eventSummaryList',
  'HIDE_ROOMING_SEARCH':'hide/eventSummaryList',
  'UPDATE_ADDRESS_FIELD':'update/uploadToMarsha',
  'ADJUST_SELECT_WIDTH':'adjust/selectWidth',
  'MAP_BOTTOM_AREA_ADJUST' : 'mapBottomArea/Adjust',
  // Component initialization related events
  'GLOBAL_RENDER': 'global-render',
  'RENDER': 'render',
  'REFRESH': 'refresh',
  'INIT': 'init',
  'RE_INIT': 'reInit',
  'REGISTERED': 'registered',
  'COMPONENTS_READY': 'ready/components',
  'ANALYTICS_TRIGGER_CLICK': 'click/analytics',
  'ADDRESS_GUEST_INFO_REFRESH': 'refresh/addressGuestInfo',
  'PHONE_EMAIL_GUEST_INFO_REFRESH': 'refresh/phoneEmailGuestInfo',
  'REFRESH_CART_SESSION': 'refresh/cartSessionTimeOut',
  'MODIFY_SEGMENT_RLM': 'refresh/modifySegmentRateList',
  'REFRESH_SESSION_BTN': 'refresh/refreshSessionNavBar',
  'RLM_SELECT_BTN_SUBMIT_FORM' : 'submit/selectBtnSubmit',
  'BOOKING_CART_REFRESH': 'refresh/refreshBookingCart',
  'REFRESH_CART_COUNT': 'refresh/refreshCartCount',
  'REFRESH_RLM_ON_REMOVE_ROOM': 'refresh/refreshOnRemoveRoom',
  'REFRESH_APPLY_OVERRIDE' : 'refresh/cancellationDetails/applyOverride',
  'REFRESH_RATE_LIST_MENU' : 'refresh/rateListMenu',
  'REFRESH_OTHER_SEARCH_OPTIONS' : 'refresh/otherSearchOptions',
  'REFRESH_ASSOCIATE_COMMENTS' : 'refresh/commentsSection',
  'REFRESH_ADDON_UNAVAILABILITY' : 'refresh/addOnUnavailability',
  'REFRESH_YOUR_REQUEST' : 'refresh/yourRequests',
  'MOG_REFRESH':'refresh/methodOfGuarantee',
  'PROPERTY_RECORDS_VIEW_CHANGE' : 'propertyRecords/view/change',
  'PROPERTY_RECORDS_SWITCH_VIEW' : 'propertyRecords/view/set',
  'STICKY_SCROLL_TOP': 'searchSummary/scroll/top',
  'STICKY_SCROLL_TO_TOP': 'searchSummary/scrollto/top',
  'BEHAVIOR_STICKY_ADD': 'searchSummary/addSticky',
  'SEARCH_FILTER_VISIBLE': 'searchFilters/visible',
  'SEARCH_FILTER_HIDDEN': 'searchFilters/hidden',
  'USER_INFO_HAS_REWARDS': 'userInformation/hasRewards',
  'MODIFYACTION_DISABLE_SUBMIT': 'disableSubmit/resBookedModifyActions',
  'HANDLE_SEGMENT_SUBMIT': 'selectRate/segmentRecords',
  'REFRESH_ORG_BOOKING_DETAILS': 'originalBookingDetails/refresh',
  'OPEN_GUEST_INFO': 'yourDetails/openGuestInfo',
  'REFRESH_COMMISSIONS_LOOKUP_RESULTS': 'refresh/lookUpFormResults',

  //Groups
  "UPDATE_FORM_DATA":"update/eventProfileForm",
  'SEND_RESLINK_COUNT':'send/reslinkcount',
  'GET_RESLINK_COUNT':'get/reslinkcount',
  'SUBMIT_RESLINK_DETAILS':'submit/reslink',
  'SEND_HOTEL_TIMESTAMPS':'send/timestamps',
  'ADD_HOTEL_CODE':'add/hotel',
  'REMOVE_HOTEL_CODE':'remove/hotel',
  'REMOVE_PROPERTY_RECORD':'remove/hotelList',
  'SHOW_SKIP_TO_CONTACT_CTA' : 'skipToContactInformation/pageNavigation',
  'UPDATE_EVENT_SUMMARY':'update/eventSummary',
  'SCROLL_TO_ATTACHMENTS': 'agentPortalAttachments/toggle',
  'GROUP_RFP_TILE_PRESENT': 'upadtePaginationHeight/makePaginationSticky',
  'TOGGLE_VISIBLE_ADDRESS_INFO': 'toggle/addressInfo',
  'TOGGLE_VISIBLE_RES_INFO': 'toggle/resInfo',
  'TOGGLE_VISIBLE_ADDRESS_COMMENTS': 'toggle/addressInfo/comments',
  'WIDGET_COLLAPSE': 'yourDetails/widget/collapse',
  'USER_INFO_HAS_REWARDS': 'userInformation/hasRewards',
  'USER_INFO_WIDGET_REFRESH': 'userInformationWidget/upcoming/refresh',
  'ADDRESS_INFO_WIDGET_REFRESH': 'addressInfoWidget/upcoming/refresh',
  'CREDIT_CARD_WIDGET_REFRESH': 'creditCardWidget/upcoming/refresh',
  'TOGGLE_VISIBLE_REW_LVL': 'toggle/resInfo/rewardLevel',
  'SNA':'ready/afterevent',
  'SHOWHIDE':'ready/showhide',
  'HIDESUCCESS':'ready/hideSuccess',
  'POINTS_EXPIRATION':'priority/one',
  'EMAIL_SUSPEND':'priority/two',
  'MOG_REFRESH':'refresh/methodOfGuarantee',
  'EMAIL_SUSPEND':'priority/three',
  'DISABLE_ENABLE_CHECKBOX':'priority/enablecheckbox',
  'ACCOUNT_MERGE':'priority/two',
  'MOG_REFRESH':'refresh/methodOfGuarantee',
  'REFRESH_CART_TILE':'refreshCart/tile',
  'REFRESH_TIMER': 'refresh/timer',
  
  //reslink
  'DISABLE_COMPANY_EVENT': 'reslink/reslinkBuilderEventInfo',
  'DISABLE_COMPANY_EVENT_TRIGGERED': 'reslink/createReslinkCTA-disableEvent',

  //------------------------- Start of Iframe specific events -------------------------//
  'TO_IFRAME_GET_CC_DATA': 'iFrame_postFrameFormFields/creditCard',
  'FROM_IFRAME_SET_CC_DATA': 'iFrame_setCCFrameData/bookNow',
  'FROM_IFRAME_CC_LOADED': 'iFrame_setCCLoadHandler/bookNow',
  'TO_IFRAME_TOGGLE_PAYMENT_OPTION': 'iFrame_togglePaymentOptionHandler/creditCard',
  'FROM_IFRAME_TOGGLE_PAYMENT_OPTION': 'iFrame_togglePaymentOptionHandler/visaCheckOut',
  'FROM_IFRAME_GET_FORM_DATA':  'iFrame_sendFormDataToCC/bookNow',
  'TO_IFRAME_SET_FORM_DATA': 'iFrame_formSubmitHandler/creditCard',
  'FROM_IFRAME_GET_PREFERENCE_PLUS_VALUE': 'iFrame_sendPreferencePlusToCC/bookNow',
  'TO_IFRAME_SET_PREFERENCE_PLUS': 'iFrame_showPPOverride/creditCard',
  'FROM_IFRAME_BIND_CLICK_EVENT': 'iFrame_bindClickEvents/bookNow',
  'TO_IFRAME_SET_SHOW_FLAG': 'iFrame_toggleCreditCardChargeMsg/creditCard',
  'FROM_IFRAME_TOGGLE_SUBMIT_BUTTON': 'iFrame_hideSubmitButton/bookNow',
  'FROM_IFRAME_BIND_PURCHASE_POINTS_CHECKBOX': 'iFrame_bindPurchasePointsCheckbox/bookNow',
  'TO_IFRAME_TOGGLE_CC_CHARGE_MESSAGE': 'iFrame_toggleCCChargeMessage/creditCard',
  'FROM_IFRAME_GET_CC_CARD_SHOW_FLAG': 'iFrame_setCCCardMessageHandler/bookNow',
  'TO_IFRAME_SET_PURCHASE_POINTS_FLAG': 'iFrame_getCCCardMessageHandler/creditCard',
  'FROM_IFRAME_REMOVE_FORM_ACTION_MESSAGES': 'iFrame_removeFormActionMessagesHandler/bookNow',
  'FROM_IFRAME_REGISTER_POP_UP': 'iFrame_registerCCPopUp/bookNow',
  'FROM_IFRAME_SET_HEIGHT': 'resizeFrame',
  'CHECK_IF_CREDITCARD_VISIBILITY_FROM_EDIT': 'iFrame_getIsCreditCardVisibleFromEdit/creditCard',
  'CHECK_IF_CREDITCARD_VISIBILITY_FROM_TOGGLE': 'iFrame_getIsCreditCardVisibleFromToggle/creditCard',
  'SHOW_CREDITCARD': 'iFrame_showCreditCard/creditCard',
  'REVERT_CC_VISIBILITY_STATUS_ONEDIT': 'setCreditCardVisibilityOnEdit/userInformationForm',
  'REVERT_CC_VISIBILITY_STATUS_ONTOGGLE': 'setCreditCardVisibilityOnToggle/userInformationForm',
  'FROM_IFRAME_TOGGLE_CC_DISPLAY': 'toggleCreditCardSection/bookNow',
  'BOOKNOW_LOADED': 'setCCLoaded/creditCard',
  //------------------------- End of Iframe specific events -------------------------//
  'SELECTED_DOMAIN': 'reslink/reslinkTermsAndConditions',
  'SHOW_CONSENT_CHECKBOX': 'reslink/showReslinkTermsAndConditions',
  'HIDE_CONSENT_CHECKBOX': 'reslink/hideReslinkTermsAndConditions',
  'OVERRIDE_PROPERTY_RECORD': 'reslink/overridePropertyData',
  //---------------------TAGP specific events------------------------//
  'SELECTED_MARSHA_CODE':'commSearchForm/enterSelectedMarshaCode'
};
